import React from 'react'

export default function Destinatari() {
  return (
    <>
      {/* <!-- ======= Features Section ======= --> */}
      <section id="destinatari" className="details">
        <div className="container">
          <div className="section-title">
            <h2>Destinatari</h2>
            <p>Caratteristiche dei destinatari del Percorso</p>
          </div>


          <div className="row content">

            <div className="col-md-8 pt-4 order-2 order-md-1">
              {/* <h3>Caratteristiche dei destinatari del Percorso</h3>  */}
              <p>
                 <strong>Logopedisti</strong>:
              </p>
              <ul>
                <li>Appassionati ai temi della comunicazione e della relazione, che vogliano perfezionare il proprio Saper Essere con pazienti e caregiver</li>
                <li>In sovraccarico emotivo per la gestione di dinamiche relazionali faticose</li>
                <li>Desiderosi di acquisire maggiore sicurezza professionale e senso di autoefficacia</li>
                <li>Interessati allo sviluppo personale e alla valorizzazione delle proprie qualità umane al servizio della professione</li>
              </ul>



              {/* <ul>
                <li><i className="bi bi-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                <li><i className="bi bi-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                <li><i className="bi bi-check"></i> Iure at voluptas aspernatur dignissimos doloribus repudiandae.</li>
                <li><i className="bi bi-check"></i> Est ipsa assumenda id facilis nesciunt placeat sed doloribus praesentium.</li>
              </ul>
              <p>
                Voluptas nisi in quia excepturi nihil voluptas nam et ut. Expedita omnis eum consequatur non. Sed in asperiores aut repellendus. Error quisquam ab maiores. Quibusdam sit in officia
              </p>  */}

            </div>
            <div className="col-md-4 order-1 order-md-2">
              <img src="assets/img/site/1/destinatari.png" className="img-fluid" alt="" />
            </div>
          </div>



        </div>
      </section>
      {/* <!-- End Features Section --> */}
    </>
  )
}
