import React from 'react'
import Contenuti from './Contenuti'
import Moduli from './Moduli'
import Obiettivi from './Obiettivi'
import Destinatari from './Destinatari'
import Costo from './Costo'
import ChiSono from './ChiSono'
import Faq from './Faq'
import Formazione from './Formazione'

export default function Home() {
    return (
        <>
        
            <Contenuti />
            <Moduli />
            <Obiettivi />
            <Destinatari />
            <Costo />
            <Formazione />
            <ChiSono />            
            <Faq />

        </>
    )
}
