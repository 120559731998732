
import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import routes from "./router/routes";

import Header from './components/layout/Header'
import Hero from './components/Hero'
import Footer from './components/layout/Footer'
import ModalIscrizione from './components/ModalIscrizione'
 


function App() {
  

  const getShow = (first) => { 
    let page = window.location.pathname
    return page === "/onboarding" ? false : true
  }
  return (
    <>

      

      {/* <Contenuti />
        <Moduli /> 
        <Obiettivi />
        <Destinatari />
        <Costo />
        <ChiSono />
        <Faq /> */}

      <BrowserRouter>
      <Header showMenu={getShow()} />
      <Hero showMenu={getShow()} />
        <main id="main">
          {/* <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/topics">Topics</Link>
              </li>
              <li>
                <Link to="/onboarding">onboarding</Link>
              </li>
            </ul> */}

          <Routes>
            {routes.map((route, index) => (
              <Route 
                key={index} 
                path={route.path} 
                element={route.main}
              //render={() => <div>Home</div>} 

              />
            ))}

          </Routes>
        </main>
      </BrowserRouter>


      <ModalIscrizione />
      <Footer showMenu={getShow()} />
    </>
  );
}

export default App;
