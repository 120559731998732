import React from 'react'
import Cta from "./Cta"

export default function Costo() {
    return (
        <>
            {/* <!-- ======= Pricing Section ======= --> */}
            <section id="pricing" className="pricing">
                <div className="container">

                    <div className="section-title">
                        <h2>Costo</h2>
                        <p>Quota di iscrizione</p>
                    </div>

                    <div className="row">

                        {/* <div className="col-lg-6 col-md-6">
                            <div className="box" data-aos="zoom-in" data-aos-delay="100">
                                <h3>Free</h3>
                                <h4><sup>$</sup>0<span> / month</span></h4>
                                <ul>
                                    <li>Aida dere</li>
                                    <li>Nec feugiat nisl</li>
                                    <li>Nulla at volutpat dola</li>
                                    <li className="na">Pharetra massa</li>
                                    <li className="na">Massa ultricies mi</li>
                                </ul>
                                <div className="btn-wrap">
                                    <a href={process.env.REACT_APP_URL_ISCRIZIONE} target="_blank" rel="noreferrer" className="btn-buy">Iscriviti</a>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-lg-6 col-md-6 mt-4 mt-md-0">
                            <div className="box featured">
                                <h3><sup>&euro;</sup>{process.env.REACT_APP_COSTO_CORSO}<span><sup style={{fontSize:"0.6em"}}>*</sup></span></h3>
                                <div>

                                    {/* <h4><sup>&euro;</sup>250<span> </span></h4> */}

                                    <ul>
                                        <li>Potenzia la tua comunicazione</li>
                                        <li>Migliora la relazione con gli utenti</li>
                                        <li>Acquisisci maggiore sicurezza</li>
                                        <li>Sperimenta un senso di autoefficacia</li>
                                    </ul>
                                    <div style={{textAlign:"right"}}><em>* iva non dovuta</em></div>
                                </div>
                                <div className="btn-wrap">
                                    <a href="#" data-bs-toggle="modal" data-bs-target="#modalIscrizione" className="btn-buy">Iscriviti</a>                                    
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">


                            <div className="details mt-3">
                                <div className="container">

                                    <div className="row content">

                                        <div className="col-12" >
                                            <h3 className="mb-3">L'iscrizione ti consente di accedere alla Formazione che include:</h3>

                                            <ul style={{ textAlign: "left" }}>
                                                <li><strong>10 Moduli con contenuti asincroni di tipo teorico-esperienziale</strong> (testo e/o video) disponibili h24 per 6 mesi, fruibili da qualsiasi dispositivo</li>
                                                <li><strong>18 Autoesplorazioni</strong>, da svolgere autonomamente, per allenare le tue capacità riflessive e creare una connessione dalla teoria a te</li>
                                                <li><strong>1 "Workbook IO Logopedista - Esercitazioni per il Saper Essere"</strong> nel quale raccogliere le tue riflessioni</li>
                                                <li><strong>2 Sessioni online sincrone, individuali,</strong> della durata di 50min, per esplorare i contenuti teorici a livello personale e in modo riservato con la docente</li>
                                                <li><strong>Sistemistica Chat, Commenti e Forum integrata,</strong> per un'esperienza di apprendimento interattiva</li>
                                                {/* <li><strong>1 Sessione aggiuntiva online sincrona, individuale</strong>, per il/la corsista con la maggiore interazione in piattaforma</li> */}
                                                <li><strong>Bibliografia e Sitografia</strong> di riferimento e di approfondimento</li>
                                                <li><strong>Attestato di partecipazione</strong></li>
                                                <li><strong>
                                                        Supervisione post Formazione
                                                    </strong><br />
                                                        Al termine del Percorso, i corsisti avranno la possibilità di usufruire di Supervisioni online a cadenza mensile e in piccolo gruppo.<br /><br /><strong>Tale corpus formativo sostiene lo sviluppo di avanzate competenze emotivo-comunicativo-relazionali del Logopedista, garantendo continuità di crescita personale e professionale.</strong> 
                                                </li>
                                                
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>






                    </div>

                </div>
            </section>
            <Cta />

            {/* <!-- End Pricing Section --> */}
        </>
    )
}
