
import React from 'react'


export default function Faq() {
    // const [counter, setCounter] = React.useState(5);

    // React.useEffect(() => {
    //     const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    //     if(counter<1)
    //         console.log("Fine")
    //     return () => clearInterval(timer);
    // }, [counter]);

    // console.log("Ci sono")

    return (
        <>

            {/* <div className="modal fade" id="modalIscrizione" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
            <div className="modal fade" id="modalIscrizione" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">

                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Iscrizione al Percorso formativo</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                            <p><strong>4 step per accedere al Percorso formativo:</strong></p>
                            <ol>
                                <li>Compila il modulo di iscrizione per fornire i dati per la fatturazione</li>
                                <li>Finalizza l'iscrizione secondo le indicazioni che ricevi in risposta automatica</li>
                                <li>Controlla la tua casella di posta elettronica. {process.env.REACT_APP_EMAIL} ti invierà il link di accesso al Percorso e la relativa fattura: <strong>verifica la cartella spam!</strong></li>
                                <li>Inserisci le credenziali in piattaforma ed iniziamo 😀</li>
                            </ol>
                            <p>
                                Ti aspetto, con gioia.
                            </p>

                            {/* <p><strong>Nota</strong></p>
                            <p>
                               Al termine della fase di iscrizione riceverai una email da {process.env.REACT_APP_EMAIL}. <strong>Ti prego di controllare anche  tra i messaggi di posta in spam</strong>.
                            </p>

                            <p>

                            <strong>Aggiungi, inoltre, tra i tuoi contatti email {process.env.REACT_APP_EMAIL}</strong> per evitare che le corrispondenze successive vadano nella cartella spam.
                            </p> */}
                            

                        </div>
                        <div className="modal-footer">
                            {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                            <a href={process.env.REACT_APP_URL_ISCRIZIONE} target="_blank"  rel="noreferrer" className="btn btn-primary">Vai al modulo di iscrizione</a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
