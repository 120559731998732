
import React from 'react'


export default function Faq() {
    return (
        <>

            {/* <!-- ======= F.A.Q Section ======= --> */}
            <section id="faq" className="faq section-bg">
                <div className="container">

                    <div className="section-title">
                        <h2>F.A.Q</h2>
                        <p>Domande frequenti</p>
                    </div>

                    <div className="faq-list">
                        <ul>
                            <li>
                                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1">Sono uno studente del CdL in Logopedia. Posso partecipare? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
                                    <p>
                                        E' preferibile essere già Logopedisti perchè il percorso, attraverso esercitazioni specifiche, richiede un lavoro di riflessione non solo sulla persona ma anche sulla propria pratica clinica. 
                                    </p>
                                </div>
                            </li>

                            <li>
                                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">Sono previsti crediti ECM? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-2" className="collapse show" data-bs-parent=".faq-list">
                                    <p>
                                        Per questa edizione non sono previsti crediti ECM.
                                    </p>
                                </div>
                            </li>

                           

                            <li data-aos="fade-up" data-aos-delay="400">
                                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">Sono previste quote agevolate di iscrizione?<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Si, per gruppi di 3 Logopedisti il costo della Formazione è di 250,00 &euro; anziché {process.env.REACT_APP_COSTO_CORSO} &euro;.
                                    </p>
                                </div>
                            </li>

                            <li data-aos="fade-up" data-aos-delay="400">
                                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">Se non riesco ad ultimare il percorso in 6 mesi cosa succede?<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                    Non preoccuparti, i 6 mesi di tempo sono indicativi, per spronarti a seguire il Percorso con un certo ritmo; tuttavia, qualsiasi necessità viene accolta con estrema flessibilità.
                                    </p>
                                </div>
                            </li>

                            <li data-aos="fade-up" data-aos-delay="400">
                                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">Quando è possibile iscriversi?<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                    IO Logopedista è un progetto formativo permanente, quindi è possibile iscriversi in qualsiasi momento e puoi seguire i contenuti quando ti è più comodo
                                    </p>
                                </div>
                            </li>

                            <li data-aos="fade-up" data-aos-delay="400">
                                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-6" className="collapsed">Una volta effettuato l'accesso in piattaforma, ci saranno momenti di confronto con la docente e con il gruppo?<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-6" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                    Si, accedendo in piattaforma potrai visualizzare le riflessioni dei colleghi che stanno frequentando la formazione e avrai a disposizione una chat per richiedere alla docente eventuali espansioni concettuali.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-7" className="collapsed">Ho bisogno di ulteriori informazioni. Come posso fare? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-7" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Puoi richiedere una videocall gratuita per poter dipanare ogni dubbio ed effettuare l'ingresso in piattaforma per vedere la struttura della Formazione dall'interno. 
                                        Per info: scrivere a <a className="p-0" style={{display: "contents"}} href={`mailto:${process.env.REACT_APP_EMAIL}`}>{process.env.REACT_APP_EMAIL}</a>
                                    </p>
                                </div>
                            </li>


                            

                        </ul>
                    </div>

                </div>
            </section>

            {/* <!-- End F.A.Q Section --> */}

        </>
    )
}
