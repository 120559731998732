import React from 'react'
import { Link } from "react-router-dom";

export default function Header(props) {
    const {showMenu} = props
    return (
        <>

            <header id="header" className="fixed-top d-flex align-items-center header-transparent">
                <div className="container d-flex align-items-center justify-content-between">

                    <div className="logo">
                        <h1>
                            <Link to="/">
                                <span id="logo"><img src="./assets/img/site/iologo-logo-white.png" width="180" alt="Logo" /></span>
                            </Link>
                            
                        </h1>

                    </div>

                    <nav id="navbar" className="navbar">
                        {showMenu &&
                        <>
                        <ul>
                            <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
                            <li><a className="nav-link scrollto" href="#contenuti">Contenuti</a></li>
                            {/* <li><a className="nav-link scrollto" href="#features">Moduli</a></li> */}
                            <li><a className="nav-link scrollto" href="#obiettivi">Obiettivi</a></li>
                            <li><a className="nav-link scrollto" href="#destinatari">Destinatari</a></li>
                            <li><a className="nav-link scrollto" href="#pricing">Costo</a></li>

                            <li>
                                <a className="nav-link scrollto" href="#formazione">Formazione in presenza</a>
                                {/* <Link to="corso-in-presenza" className="nav-link scrollto">Corso</Link> */}
                            </li>
                            <li><a className="nav-link scrollto" href="#team">Chi sono</a></li>
                            <li><a className="nav-link scrollto" href="#faq">Faq</a></li>
                                

                            <li>
                                 <a className="nav-link scrollto" href="#null" data-bs-toggle="modal" data-bs-target="#modalIscrizione">Iscriviti</a>
                            </li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                        </>
                        }
                    </nav>


                </div>
            </header>
        </>
    )
}
