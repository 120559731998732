import React from 'react'

export default function Obiettivi() {
  return (
    <>
      {/* <!-- ======= Features Section ======= --> */}
      <section id="obiettivi" className="details">
        <div className="container">

          <div className="section-title">
            <h2>Obiettivi</h2>
            <p>Obiettivi formativi</p>
          </div>

          <div className="row content">
            <div className="col-md-4">
              <img src="assets/img/site/1/obiettivi.png" className="img-fluid" alt="" />
            </div>
            <div className="col-md-8">
              <h3>A breve termine:</h3>
              {/* <p className="fst-italic">
            Acquisire maggiore consapevolezza di te, come persona e come professionista;
            </p> */}
              <ul>
                <li>Acquisire maggiore consapevolezza di te, come persona e come professionista</li>
                <li>Sviluppare abilità comunicative interne ed esterne più efficaci</li>
                <li>Migliorare la relazione con i pazienti, i caregiver...e con te</li>
                <li>Sostenere la motivazione per la costruzione della squadra riabilitativa</li>

              </ul>

              <h3>A lungo termine:</h3>
              {/* <p className="fst-italic">
            Acquisire maggiore consapevolezza di te, come persona e come professionista;
            </p> */}
              <ul>
                <li>Rafforzare la tua identità professionale attraverso la percezione di un rinnovato equilibrio interiore, maggior senso di autoefficacia e sicurezza nel tuo agire logopedico</li>
                <li>Prevenire stati di stress lavoro-correlato e la sindrome del burnout grazie alla capacità di stabilire migliori confini professionali e all'attitudine ad aver cura anche di te</li>
                <li>Comprendere di essere parte di qualcosa di più grande e concepire la Logopedia come uno dei canali che ti consente di "fare la tua parte per rendere migliore questo mondo"</li>
              </ul>
              {/* <p>
              Voluptas nisi in quia excepturi nihil voluptas nam et ut. Expedita omnis eum consequatur non. Sed in asperiores aut repellendus. Error quisquam ab maiores. Quibusdam sit in officia
            </p> */}
            </div>
          </div>



        </div>
      </section>
      {/* <!-- End Features Section --> */}
    </>
  )
}
