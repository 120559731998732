import React from 'react'
import useImportScript from '../../helper/customHooks/importScript'


export default function Footer(props) {
    const { showMenu } = props

    useImportScript("./assets/vendor/purecounter/purecounter_vanilla.js")
    useImportScript("./assets/vendor/aos/aos.js")
    useImportScript("./assets/vendor/bootstrap/js/bootstrap.bundle.min.js")
    useImportScript("./assets/vendor/glightbox/js/glightbox.min.js")
    useImportScript("./assets/vendor/swiper/swiper-bundle.min.js")
    useImportScript("./assets/vendor/php-email-form/validate.js")
    useImportScript("./assets/js/main.js")

    return (
        <> 

            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-6">
                                <div className="footer-info">
                                    <h3>IO Logopedista</h3>
                                    <p className="pb-3"><em>La prima Formazione in Italia per il Saper Essere<br />dedicata ai Logopedisti</em></p>
                                    <p>
                                        {/* A108 Adam Street <br />
                                        NY 535022, USA<br /> */}
                                        <strong>Telefono:</strong> {process.env.REACT_APP_TELEFONO}<br />
                                        <strong>Email:</strong> <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>{process.env.REACT_APP_EMAIL}</a><br />
                                    </p>
                                    <div className="social-links mt-3">

                                        <a href={process.env.REACT_APP_FACEBOOK} target="_blank" rel="noreferrer" className="facebook"><i className="bx bxl-facebook"></i></a>
                                        <a href={process.env.REACT_APP_LINKEDIN} target="_blank" rel="noreferrer" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                                        <a href={process.env.REACT_APP_INSTAGRAM} target="_blank" rel="noreferrer" className="instagram"><i className="bx bxl-instagram"></i></a>

                                    </div>
                                </div>
                            </div>

                            {showMenu &&
                                <div className="col-lg-2 col-md-6 footer-links">
                                    <h4>Links</h4>
                                    <ul>
                                        <li><i className="bx bx-chevron-right"></i> <a href="#hero" className="nav-link scrollto">Home</a></li>
                                        <li><i className="bx bx-chevron-right"></i> <a href="#contenuti" className="nav-link scrollto">Contenuti</a></li>
                                        <li><i className="bx bx-chevron-right"></i> <a href="#obiettivi" className="nav-link scrollto">Obiettivi</a></li>
                                        <li><i className="bx bx-chevron-right"></i> <a href="#destinatari" className="nav-link scrollto">Destinatari</a></li>
                                        <li><i className="bx bx-chevron-right"></i> <a href="#pricing" className="nav-link scrollto">Costo</a></li>
                                        <li><i className="bx bx-chevron-right"></i> <a href="#team" className="nav-link scrollto">Chi sono</a></li>
                                        <li><i className="bx bx-chevron-right"></i> <a href="#faq" className="nav-link scrollto">Faq</a></li>
                                        <li><i className="bx bx-chevron-right"></i> <a href="https://www.iubenda.com/privacy-policy/70954109" target="_blank" className="nav-link scrollto">Privacy</a></li>
                                        <li><i className="bx bx-chevron-right"></i> <a href="https://www.iubenda.com/privacy-policy/70954109/cookie-policy" target="_blank" className="nav-link scrollto">Cookie policy</a></li>

                                        
                                    </ul>
                                </div>
                            }

                            <div className="col-lg-6 col-md-6 footer-links">
                                {/* <h4>Our Services</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
                                </ul> */}
                                <h4>IO Logopedista</h4>
                                <p> Migliora le tue competenze comunicativo-relazionali, acquisisci maggiore sicurezza, sperimenta un senso di autoefficacia e sostieni la motivazione, in un percorso identitario.</p>
                                {showMenu && <a className="cta-btn align-middle" href="#" data-bs-toggle="modal" data-bs-target="#modalIscrizione">Iscriviti</a>}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="col-lg-12 col-md-12">
                                <div className="footer-info">
                                    <h3>IO Logopedista</h3>
                                    
                                    <p>
                                    La prima Formazione in Italia per il Saper Essere<br />dedicata ai Logopedisti
                                       
                                    </p>
                                    <p className="mt-3">
                                       <strong>Pagina Facebook/Instagram di riferimento:<br /><a href="https://www.facebook.com/iologopedista" target="_blank"  rel="noreferrer">Logopedia e abilità di counseling</a></strong> <br />
                                    </p>
                                    <p className="mt-3">

                                       <span ><strong >Email:</strong> <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>{process.env.REACT_APP_EMAIL}</a></span>
                                    </p>
                                     
                                </div>
                            </div> */}




                <div className="container">
                    <div className="copyright mb-3">
                        2024 - &copy; <strong><span>IoLogopedista</span></strong>
                        {/* All Rights Reserved */}
                    </div>
                    <div className='text-center'>
                        <small>
                            Logopedista iscritta all'Albo della professione sanitaria di Logopedista presso l'Ordine TSRM PSTRP di Bari Taranto (n.34)<br />
                            Psicologa iscritta all'Albo A dell'Ordine degli Psicologi Puglia (n.6793)<br />
                            P.IVA: 02130510676
                        </small>
                    </div>
                </div>
            </footer>



            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
            {/* <div id="preloader"></div> */}



        </>
    )
}
