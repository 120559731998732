import React from 'react'


export default function Formazione() {
  return (
    <>

      <section id="formazione" className="features moduli">
        <div className="container">
          <div className="section-title">
            <h2>Formazione</h2>
            <p>Formazione in presenza</p>
          </div>

          <div className="row content">
            
            <div className="col-md-7">
              <h3 className="mb-3">
                 Novità 2024!<br />
              </h3>

              
              "IO Logopedista - <strong>Formazione</strong> per il Saper Essere" è realizzabile <strong>in presenza</strong> per costruire 
              insieme migliori competenze comunicativo-relazionali al servizio degli utenti e a tutela e soddisfazione dei professionisti.
              
            

              <section id="pricing" className="pricing pt-4 pb-4">
                        <div className="row content">
                          <div className="col-md-6 mt-4 mt-md-0">
                              <div className="box featured">
                                  <h3>Dove?</h3>
                                  <div>

                                  <strong>Nel tuo studio professionale</strong><br /> 
                                  con i colleghi che desideri coinvolgere!

                                      
                                  </div>
                                  {/* <div className="btn-wrap">
                                      <a className="cta-btn" href="https://shorturl.at/cAEHJ" target="_blank" rel="noreferrer">Programma Roma aprile 2024</a>                   
                                  </div> */}
                              </div>
                          </div>

                          <div className="col-md-6 mt-4 mt-md-0">
                              <div className="box featured">
                                  <h3>Come?</h3>
                                  <div>

                                  Scrivi a:<br /> 
                                
                                  <a href={`mailto:${process.env.REACT_APP_EMAIL}`}><strong>{process.env.REACT_APP_EMAIL}</strong></a>

                                      
                                  </div>
                                  {/* <div className="btn-wrap">
                                      <a className="cta-btn" href="https://shorturl.at/cAEHJ" target="_blank" rel="noreferrer">Programma Roma aprile 2024</a>                           
                                  </div> */}
                              </div>
                          </div>
                          
                        </div>
              </section>
                

              <p className="italianno-regular mb-4 text-center">
                  Per rendere migliore la tua quotidianità professionale <strong>investi su di Te</strong>:<br />sul benessere, sulla comunicazione e sulla relazione.
              </p>

 
              <h3 className="mb-3">
                 Elenco corsi<br />
              </h3>
              <div className='mt-3 mb-3'>
                          <a className="cta-btn" href="https://www.iologopedista.it/assets/share/IO-Logopedista_Formazione-CMR-Roma.pdf" target="_blank" rel="noreferrer">CMR-ROMA aprile 2024 Programma formativo</a>
              </div>

 
 

            </div>

            <div className="col-md-5" >
              <img src="assets/img/site/formazione-1.jpg" className="img-fluid mb-2" alt="" />
              <img src="assets/img/site/formazione-2.jpg" className="img-fluid" alt="" />
            </div>

          </div>
        </div>
      </section>

    </>
  )
}
