import React from 'react'
import Cta from "./Cta"


export default function Moduli() {

  const myScroll = (element) => {
    let offset = 150
    let elementPos = document.getElementById(element).offsetTop
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth'
    })
  }

  const getModuleItem = (item, label, active) => {
    const tab = `#tab-${item}`
    return (
      <>
        <li className="nav-item aos-init aos-animate" role="presentation">
          <a className={`nav-link ${active} show`} data-bs-toggle="tab" href={tab} onClick={e => myScroll("sideContent")} aria-selected="false" role="tab" tabIndex="-1">
            {/* <i className="ri-gps-line"></i> */}
            <h4 className=" d-lg-block blue">{item}. {label}</h4>
          </a>
        </li>
      </>
    )
  }


  //Per fabio: vedi tpl a questo indirizzo: https://bootstrapmade.com/demo/Selecao/ sezione html features
  return (
    <>



      <section id="features" className="features moduli pt-0">
        <div className="container">

          <div className="content">
            <h3 className="mb-3">
              I moduli del Percorso formativo:
            </h3>
          </div>



          <div className="row">
            <div className="col-md-4 col-sm-12">
              <ul className="nav nav-tabs row d-flex" role="tablist">
                {getModuleItem(1, "Mi presento", "active")}
                {getModuleItem(2, "Percorso formativo", null)}
                {getModuleItem(3, "La bussola professionale", null)}
                {getModuleItem(4, "La farfalla della professionalità", null)}
                {getModuleItem(5, "Consapevolezza personale", null)}
                {getModuleItem(6, "Comunicazione interna", null)}
                {getModuleItem(7, "Consapevolezza professionale", null)}
                {getModuleItem(8, "Comunicazione esterna", null)}
                {getModuleItem(9, "Conclusioni", null)}
                {getModuleItem(10, "Bibliografia e sitografia", null)}
              </ul>
            </div>


            <div className="col-md-8 col-sm-12" >

              <div className="tab-content mt-0 aos-init aos-animate">

                <span id="sideContent"></span>

                <div className="tab-pane active" id="tab-1" role="tabpanel">
                  <div className="row content">
                    <div className="col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0">
                      <h3>Mi presento.</h3>
                      {/* <p className="fst-italic"> Ti dico qualcosa di me e del motivo per il quale ho formulato questo Percorso specifico per i Logopedisti.</p>  */}
                      <ul>
                        <li> Qualcosa di me, per entrare in relazione con te</li>
                      </ul>
                    </div>
                    {/* <div className="col-lg-4 order-1 order-lg-2 text-center"> <img src="assets/img/site/1/me2.png" alt="" className="img-fluid" /></div> */}
                    <div className="col-lg-4 order-1 order-lg-2 text-cente team">
                      <div className="member" >
                        <div className="pic"><img src="assets/img/site/1/me.png" className="img-fluid" alt="" /></div>
                        <div className="member-info">
                          <h4>Francesca Verni</h4>
                          <span>Logopedista e Psicologa</span>
                          <div className="social">
                            <a href={process.env.REACT_APP_FACEBOOK} target="_blank" rel="noreferrer"><i className="bi bi-facebook"></i></a>
                            <a href={process.env.REACT_APP_LINKEDIN} target="_blank" rel="noreferrer"><i className="bi bi-linkedin"></i></a>
                            <a href={process.env.REACT_APP_INSTAGRAM} target="_blank" rel="noreferrer"><i className="bx bi-instagram"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="tab-pane" id="tab-2" role="tabpanel">
                  <div className="row content">
                    <div className="col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0">
                      <h3>Percorso formativo</h3>
                      {/* <p>Obiettivi, struttura e materiali a tua disposizione, postura da adottare per un apprendimento significativo e caratteristiche dei destinatari del Percorso formativo.</p> */}
                      {/* <p className="fst-italic"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                      <ul>
                        <li>Obiettivi</li>
                        <li>Struttura e materiali a tua disposizione</li>
                        <li>Postura per un apprendimento significativo</li>

                      </ul>
                    </div>
                    <div className="col-lg-4 order-1 order-lg-2 text-center"> <img src="assets/img/site/percorso_formativo.png" alt="" className="img-fluid" /></div>
                  </div>
                </div>

                <div className="tab-pane" id="tab-3" role="tabpanel">
                  <div className="row content">
                    <div className="col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0">
                      <h3>La bussola professionale</h3>
                      {/* <p> Se hai deciso di viaggiare con me per esplorare i saperi relazionali della tua professionalità, l'appuntamento non può che essere al piano -1, a livello della radice, là dove sono poste le fondamenta dell'essere Logopedista e dove tornerai, in caso di smarrimento, lungo tutto l'arco della tua pratica lavorativa, esattamente come si torna in un porto sicuro.</p> */}
                      <ul>
                        <li>Il Codice Deontologico del Logopedista</li>
                        <li>Contributo video sul Codice Deontologico</li>
                        <li>La Costituzione etica delle Professioni sanitarie</li>
                        <li>Contributo video sulla Costituzione etica</li>
                      </ul>
                      {/* <p className="fst-italic"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                    </div>
                    <div className="col-lg-4 order-1 order-lg-2 text-center"> <img src="assets/img/site/bussola_professionale.jpg" alt="" className="img-fluid" /></div>
                  </div>
                </div>

                <div className="tab-pane" id="tab-4" role="tabpanel">
                  <div className="row content">
                    <div className="col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0">
                      <h3>La Farfalla della Professionalità</h3>
                      {/* <p> In questo modulo ti accompagno nella conoscenza delle 4 ali della professionalità descritte dal Prof. Corrado Ziglio nel suo testo "Viaggio nelle tribù professionali. Processi di deterioramento e strategie formative"  (lo trovi in bibliografia). </p> */}

                      <ul>
                        <li>La prima ala: i saperi tecnico-professionali</li>
                        <li>Contributo video sulla prima ala della professionalità</li>
                        <li>Autoesplorazione sulla prima ala della professionalità</li>
                        <li>La seconda ala: i registri comunicativi</li>
                        <li>Contributo video sulla seconda ala della professionalità</li>
                        <li>Autoesplorazione sulla seconda ala della professionalità</li>
                        <li>La terza ala: la consapevolezza del ruolo</li>
                        <li>Contributo video sulla terza ala della professionalità</li>
                        <li>Autoesplorazione sulla terza ala della professionalità</li>
                        <li>La quarta ala: il carattere della persona che esercita una professione</li>
                        <li>Contributo video sulla quarta ala della professionalità</li>
                        <li>Autoesplorazione sulla quarta ala della professionalità</li>

                      </ul>
                    </div>
                    <div className="col-lg-4 order-1 order-lg-2 text-center"> <img src="assets/img/site/la_farfalla.jpg" alt="" className="img-fluid" /></div>
                  </div>
                </div>

                <div className="tab-pane" id="tab-5" role="tabpanel">
                  <div className="row content">
                    <div className="col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0">
                      <h3>Consapevolezza Personale</h3>
                      {/* <p>In questo modulo ci soffermeremo su alcuni passi salienti dell'IO persona, utili alla professione del Logopedista.</p> */}

                      <ul>
                        <li>L'immersione nell'IO persona che sostiene l'IO Logopedista</li>
                        <li>Qual è il tuo canale preferenziale di comunicazione?</li>
                        <li>Contributo video sugli stili comunicativi</li>
                      </ul>
                    </div>
                    <div className="col-lg-4 order-1 order-lg-2 text-center"> <img src="assets/img/site/consapevolezza_personale.jpg" alt="" className="img-fluid" /></div>
                  </div>
                </div>

                <div className="tab-pane" id="tab-6" role="tabpanel">
                  <div className="row content">
                    <div className="col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0">
                      <h3>Comunicazione interna</h3>
                      {/* <p>Questo modulo è dedicato all'osservazione della tua comunicazione interna, intesa sia come dialogo interiore sia come reazione comportamentale a trigger professionali.</p> */}

                      <ul>
                        <li>Guardarsi allo specchio: che cosa penso e provo durante le interazioni professionali</li>
                        <li>Autoesplorazione sul dialogo interiore</li>
                        <li>Il corpo e la relazione necessitano di movimenti di "accomodamento"</li>
                        <li>Autoesplorazione sulla percezione del tuo corpo durante i trattamenti</li>
                        <li>Che cosa mi attrae e che cosa mi distrae durante il lavoro</li>
                        <li>Autoesplorazione sulle fluttuazioni attentive</li>
                        <li>Contributo video sulla comunicazione interna e input per la gestione di trattamenti emotivamente sfidanti</li>
                        <li>Autoesplorazione sulla gestione dello stress</li>
                        <li>Prima sessione online INDIVIDUALE</li>
                      </ul>
                    </div>
                    <div className="col-lg-4 order-1 order-lg-2 text-center"> <img src="assets/img/site/comunicazione_interna.jpg" alt="" className="img-fluid" /></div>
                  </div>
                </div>

                <div className="tab-pane" id="tab-7" role="tabpanel">
                  <div className="row content">
                    <div className="col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0">
                      <h3>Consapevolezza professionale</h3>
                      {/* <p>In questo modulo ci concentreremo sulla sfera professionale, per acquisirne maggiore consapevolezza, e sull'atmosfera che si genera durante gli scambi comunicativo-relazionali nel setting logopedico.</p> */}
                      <ul>
                        <li>Metacognizione professionale: riflessioni sull'attività logopedica</li>
                        <li>Autoesplorazione sulla metacognizione professionale</li>
                        <li>Contributo video sulla metacognizione professionale</li>
                        <li>Il ciclo del contatto gestaltico</li>
                        <li>Il ciclo del contatto logopedico</li>
                        <li>Fase iniziale del percorso logopedico: abilità comunicativo-relazionali specifiche</li>
                        <li>Fase centrale del percorso logopedico: abilità comunicativo-relazionali specifiche</li>
                        <li>Fase finale del percorso logopedico: abilità comunicativo-relazionali specifiche</li>
                        <li>Autoesplorazione sul ciclo del contatto logopedico</li>
                        <li>Il contesto professionale e il clima relazionale in Logopedia</li>
                        <li>Autoesplorazione sul contesto professionale e il clima relazionale in Logopedia</li>

                      </ul>
                    </div>
                    <div className="col-lg-4 order-1 order-lg-2 text-center"> <img src="assets/img/site/consapevolezza_professionale.jpg" alt="" className="img-fluid" /></div>
                  </div>
                </div>

                <div className="tab-pane" id="tab-8" role="tabpanel">
                  <div className="row content">
                    <div className="col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0">
                      <h3>Comunicazione esterna</h3>
                      {/* <p>Questo modulo è dedicato alla comunicazione esterna, quindi a quel sistema complesso di significati che da te arriva all'altro e che a te ritorna, con circolarità. In questa sezione ti invito a riflettere anche sull'alleanza che, con la qualità della tua presenza, puoi creare nel percorso riabilitativo, a vantaggio sia della relazione col paziente sia del tuo benessere in termini di soddisfazione e senso di pienezza umana. Puoi immaginare l'alleanza come una danza che si fa (almeno) in due: a muovere i passi siete tu ed il paziente, tuttavia, a volte, il tuo partner...potresti essere solo Tu.</p> */}
                      <ul>

                        <li>Il counseling logopedico</li>
                        <li>Contributo video sull'Effetto prima impressione</li>
                        <li>Autoesplorazione sull'Effetto prima impressione</li>
                        <li>Contributo video sull’Ascolto attivo e Tecniche di riformulazione</li>
                        <li>Autoesplorazione sull'Ascolto attivo e Tecniche di riformulazione</li>
                        <li>Contributo video sulla Qualità della comunicazione</li>
                        <li>Autoesplorazione sulla Qualità della comunicazione</li>
                        <li>Contributo video sul Modificare lo stile comunicativo</li>
                        <li>Autoesplorazione su Modificare lo stile comunicativo</li>
                        <li>Contributo video su "Il modo crea la differenza e potenzia la bontà della sostanza"</li>
                        <li>Il semaforo dell'alleanza</li>
                        <li>Autoesplorazione sull'alleanza</li>
                        <li>Seconda sessione online in INDIVIDUALE</li>
                      </ul>
                    </div>
                    <div className="col-lg-4 order-1 order-lg-2 text-center"> <img src="assets/img/site/comunicazione_esterna.jpg" alt="" className="img-fluid" /></div>
                  </div>
                </div>

                <div className="tab-pane" id="tab-9" role="tabpanel">
                  <div className="row content">
                    <div className="col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0">
                      <h3>Conclusioni</h3>
                      {/* <p>Ti accompagno, adesso, nella fase conclusiva di questo Percorso formativo sul Saper Essere. </p> */}
                      <ul>
                        <li>L'elisir della qualità professionale</li>
                        <li>Lettura di una testimonianza per ascoltare i bisogni di chi riceve le cure</li>
                        <li>Il mio Qui e Ora logopedico: cosa so del mio presente professionale e cosa desidero per il mio futuro</li>
                        <li>Contributo video "Per Passione Ricevuta"</li>
                      </ul>
                    </div>
                    <div className="col-lg-4 order-1 order-lg-2 text-center"> <img src="assets/img/site/conclusioni.jpg" alt="" className="img-fluid" /></div>
                  </div>
                </div>

                <div className="tab-pane" id="tab-10" role="tabpanel">
                  <div className="row content">
                    <div className="col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0">
                      <h3>Bibliografia e sitografia</h3>
                      {/* <p>Di seguito, troverai una serie di risorse bibliografiche e la sitografia di approfondimento.</p> */}
                      <ul>
                        <li>Bibliografia di riferimento</li>
                        <li>Sitografia di approfondimento</li>

                      </ul>
                    </div>
                    <div className="col-lg-4 order-1 order-lg-2 text-center"> <img src="assets/img/site/bibliografia.jpg" alt="" className="img-fluid" /></div>
                  </div>
                </div>


              </div>
            </div>


          </div>


        </div>
      </section>
      <Cta />
    </>
  )
}

// function Moduli_bak() {
//   return (
//     <>
//       {/* <!-- ======= Features Section ======= --> */}
//       <section id="features" className="faq section-bg">
//         <div className="container">






//           <div className="section-title" data-aos="fade-up">
//             <h2>Moduli</h2>
//             <p>I moduli del percorso formativo</p>
//           </div>

//           <div className="faq-list">
//             <ul>
//               <li data-aos="fade-up">
//                 <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1">Mi presento <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
//                 <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
//                   <p>
//                     Ti dico qualcosa di me e del motivo per il quale ho formulato questo Percorso specifico per i Logopedisti.
//                   </p>
//                 </div>
//               </li>

//               <li data-aos="fade-up" data-aos-delay="100">
//                 <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">Percorso Formativo<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
//                 <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
//                   <ul>
//                     <li>Obiettivi</li>
//                     <li>Struttura e materiali a tua disposizione</li>
//                   </ul>

//                 </div>
//               </li>

//               <li data-aos="fade-up" data-aos-delay="200">
//                 <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">Dolor sit amet consectetur adipiscing elit? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
//                 <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
//                   <p>
//                     Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
//                   </p>
//                 </div>
//               </li>

//               <li data-aos="fade-up" data-aos-delay="300">
//                 <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">Tempus quam pellentesque nec nam aliquam sem et tortor consequat? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
//                 <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
//                   <p>
//                     Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in. Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est. Purus gravida quis blandit turpis cursus in.
//                   </p>
//                 </div>
//               </li>

//               <li data-aos="fade-up" data-aos-delay="400">
//                 <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">Tortor vitae purus faucibus ornare. Varius vel pharetra vel turpis nunc eget lorem dolor? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
//                 <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
//                   <p>
//                     Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris vitae ultricies leo integer malesuada nunc vel. Tincidunt eget nullam non nisi est sit amet. Turpis nunc eget lorem dolor sed. Ut venenatis tellus in metus vulputate eu scelerisque.
//                   </p>
//                 </div>
//               </li>

//             </ul>
//           </div>



//         </div>
//       </section>
//       {/* <!-- End Features Section --> */}
//     </>
//   )

