import React from "react";
// import Dashboard from "../components/dashboard/Dashboard";
// import OpenItems from "../components/openItems/OpenItems";
// import MasterData from "../components/masterData/MasterData";
// import Investigation from "../components/investigation/Investigation";
// import Monitor from "../components/monitor/Monitor";
// import Statistic from "../components/statistic/Statistic";
// import TestEngine from "../components/testEngine/TestEngine";
// import Administration from "../components/administration/Administration";
import OnBoarding from "../components/OnBoarding";
import Home from "../components/Home"

const baseUrl = "/";

const routes = [
  {
    name: "base",
    exact: true,
    path: `${baseUrl}`,
    protected: false,
    main: <Home />,
  
    // main: <>Ciao</>
  },
  {
    name: "home",
    exact: true,
    path: `/home`,
    protected: false,
    main:  <Home />,
    
  },
  {
    name: "onboarding",
    exact: true,
    path: `/onboarding`,
    protected: false,
    //main: (props) => <>Topics</>
    main:  <OnBoarding />
    
  },
  // {
  //   name: "about",
  //   exact: true,
  //   path: `/about`,
  //   protected: false,
  //   //main: (props) => <>Mondo</>
  //   main: <>Mondo</>,
    
  // },
  // {
  //   name: "topics",
  //   exact: true,
  //   path: `/topics`,
  //   protected: false,
  //   //main: (props) => <>Topics</>
  //   main: <>Topics</>,
    
  // },
  
   
];

export default routes;
