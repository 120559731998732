import React from 'react'

export default function Cta() {
    return (
        <>

            <section id="cta" className="cta">
                <div className="container">
                    <div className="row aos-init aos-animate" data-aos="zoom-out">
                        <div className="col-lg-9 text-center text-lg-start">
                            <h3>IO Logopedista</h3>
                            <p> Migliora le tue competenze comunicativo-relazionali, acquisisci maggiore sicurezza, sperimenta un senso di autoefficacia e sostieni la motivazione, in un percorso identitario.</p>
                        </div>
                        <div className="col-lg-3 cta-btn-container text-center"> 
                            <a className="cta-btn align-middle" href="#" data-bs-toggle="modal" data-bs-target="#modalIscrizione">Iscriviti</a>
                            
                            
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}


