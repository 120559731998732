import React from 'react'



export default function Hero(props) {

    const { showMenu } = props
    const HeroFull = () => {
        return (
            <>
                {/* <!-- ======= Hero Section ======= --> */}
                <section id="hero">

                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
                                <div>
                                    <h1>IO Logopedista</h1>
                                    <h2 className="h2_hero">
                                        La prima Formazione in Italia <br />per il <span>Saper Essere</span><br />
                                        dedicata ai <span>Logopedisti</span>
                                    </h2>

                                    <h2>Dott.ssa Francesca Verni, Logopedista e Psicologa</h2>
                                    <div className="text-center text-lg-start">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#modalIscrizione" className="btn-get-started scrollto">Iscriviti</a>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2 hero-img" >
                                <img src="assets/img/hero-img.png" className="img-fluid animated" alt="" />
                            </div>
                        </div>
                    </div>

                    <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
                        <defs>
                            <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g className="wave1">
                            <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
                        </g>
                        <g className="wave2">
                            <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
                        </g>
                        <g className="wave3">
                            <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
                        </g>
                    </svg>

                </section>
                {/* <!-- End Hero --> */}
            </>
        )
    }

    const HeroLight = () => {
        return (
            <>
                {/* <!-- ======= Hero Section ======= --> */}
                <section id="hero">

                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-lg-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
                                <div>
                                    <h1>IO Logopedista</h1>
                                    <h2 className="h2_hero">
                                        La prima Formazione in Italia per il <span>Saper Essere</span> <br />dedicata ai <span>Logopedisti</span>
                                    </h2>

                                    <h2>Dott.ssa Francesca Verni, Logopedista e Psicologa</h2>
                                    {/* <div className="text-center text-lg-start">
                                        <a href={process.env.REACT_APP_URL_ISCRIZIONE} target="_blank" rel="noreferrer" className="btn-get-started scrollto">Iscriviti</a>
                                    </div> */}
                                </div>
                            </div>

                        </div>
                    </div>

                    <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
                        <defs>
                            <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g className="wave1">
                            <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
                        </g>
                        <g className="wave2">
                            <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
                        </g>
                        <g className="wave3">
                            <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
                        </g>
                    </svg>

                </section>
                {/* <!-- End Hero --> */}
            </>
        )
    }

    return (
        showMenu ? <HeroFull /> : <HeroLight />

    )
}
