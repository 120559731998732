import React from 'react'

export default function OnBoarding(props) {
    // console.log(props);
    return (
        <>
            {/* <!-- ======= Features Section ======= --> */}
            <section id="obiettivi" className="details">
                <div className="container">

                    {/* <div className="section-title">
                        <h2>Obiettivi</h2>
                        <p>Finalizza l'iscrizione</p>
                    </div> */}

                    <div className="row content">

                        <div className="col-md-12">
                            <h3>Finalizza l'iscrizione:</h3>
                            {/* <p className="fst-italic">
                            Acquisire maggiore consapevolezza di te, come persona e come professionista;
                            </p> */}

                            <p>
                                Gentile collega,<br />                               
                                puoi finalizzare la procedura versando la quota d’iscrizione di {process.env.REACT_APP_COSTO_CORSO} euro tramite bonifico bancario intestato a:
                           
                           </p>
                                <ul className='mt-3'>
                                    <li className="pb-0">Francesca Verni</li>
                                    <li className="pb-0">IBAN: IT21E0503401753000000085145</li>
                                    <li className="pb-0">Causale: Formazione e il tuo nome e cognome</li>
                                </ul>
                            <p>

                            NON è necessario inviare la contabile del bonifico.<br />
                            Controlla la tua casella di posta elettronica. Al rilevamento della quota d'iscrizione riceverai da {process.env.REACT_APP_EMAIL} il link di accesso al Percorso e la relativa fattura: <strong>verifica la cartella spam!</strong><br />
                            </p>
                            <p>
                            A quel punto, inserisci le credenziali in piattaforma ed iniziamo 😊  
                            </p>
                            <p>
                            A presto!<br />
                            IO Logopedista 
                            </p>
                            <p className="fst-italic">
                                <strong>PS: Aggiungi tra i tuoi contatti email {process.env.REACT_APP_EMAIL} per evitare che le corrispondenze successive vadano nella cartella spam.</strong>
                            </p>
                        </div>
                    </div>



                </div>
            </section>



        </>
    )
}
