import React from 'react'

export default function ChiSono() {
    return (
        <>
            {/* <!-- ======= Team Section ======= --> */}


            <section id="team" className="team">
                <div className="container">

                    <div className="section-title">
                        <h2>Chi sono</h2>
                        <p>Dott.ssa Francesca Verni</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                            <div className="member" >
                                <div className="pic"><img src="assets/img/site/1/me2.png" className="img-fluid" alt="" /></div>
                                <div className="member-info">
                                    <h4>Francesca Verni</h4>
                                    <span>Logopedista e Psicologa</span>
                                    <div className="social">

                                        <a href={process.env.REACT_APP_FACEBOOK} target="_blank" rel="noreferrer"><i className="bi bi-facebook"></i></a>
                                        <a href={process.env.REACT_APP_LINKEDIN} target="_blank" rel="noreferrer"><i className="bi bi-linkedin"></i></a>
                                        <a href={process.env.REACT_APP_INSTAGRAM} target="_blank" rel="noreferrer"><i className="bx bi-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 order-2 order-md-1" >
                            <h3>Chi sono</h3>
                            <p >
                                {/* className="fst-italic" */}
                                Sono un’appassionata di comunicazione e di relazione.
                            </p>
                            <p>
                                Sento, penso, studio e vivo i rapporti umani con molta intensità e verità.
                            </p>
                            <p>
                                Ho compreso che non è il tempo che trascorro con gli altri a definirne il valore
                                relazionale ma la profondità che, insieme, si può raggiungere, anche a distanza di
                                poco tempo dalla conoscenza.
                            </p>
                            <p>
                                Molto del mio senso della vita è nei frangenti di quotidianità logopedica: una parola
                                letta meglio, un ragno scritto senza la i, un “ho parlato proprio bene ora”, un sospiro
                                di fierezza, un abbraccio di gratitudine, degli occhi colmi di riconoscenza.
                            </p>
                            <p>
                                Se mi viene chiesto, io aiuto. E questa è la mia postura, non solo professionale.
                            </p>

                            <p>
                                Rifuggo dalla superficialità e da false interazioni: nei rapporti, alla quantità preferisco
                                la qualità. Amo l’ironia, la consapevolezza e l’onestà, tanto quanto la mia famiglia,
                                l’amicizia, l’arte, viaggiare e pensare.
                            </p>

                            <p>
                                Sono Logopedista, Psicologa e Counselor. Ma tutto questo è solo la conseguenza, e
                                non l’origine, di ciò che ti ho appena detto di me.
                            </p>


                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Team Section --> */}

        </>
    )
}
