import React from 'react'


export default function Contenuti() {
  return (
    <>

      <section id="contenuti" className="features moduli">
        <div className="container">
          <div className="section-title">
            <h2>Contenuti</h2>
            <p>I Contenuti del corso</p>
          </div>

          <div className="row content">
            <div className="col-md-4" >
              <img src="assets/img/site/1/contenuti.png" className="img-fluid" alt="" />
            </div>
            <div className="col-md-8">

              <h3 className="mb-3">
                I contenuti sono organizzati in 10 moduli per stimolare la tua crescita su 4 livelli:<br />
              </h3>
              <ul style={{ listStyle: "none" }} className="pl-0">
                <li>Consapevolezza personale</li>
                <li>Comunicazione interna</li>
                <li>Consapevolezza professionale</li>
                <li>Comunicazione esterna</li>
              </ul>

            </div>
          </div>
        </div>
      </section>

    </>
  )
}
